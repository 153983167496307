import { createContext, useState } from 'react';

const QuestionContext = createContext({
    ctxQuestions: null,
    setCtxQuestions: () => { },
    ctxActiveQuestion: 0,
    setCtxActiveQuestion: () => { },
    ctxAnswers: null,
    setCtxAnswers: () => { }
});

export const QuestionContextProvider = (props) => {

    const [activeQuestion, setActiveQuestion] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);

    const contextValue = {
        ctxQuestions: questions,
        setCtxQuestions: setQuestions,
        ctxActiveQuestion: activeQuestion,
        setCtxActiveQuestion: setActiveQuestion,
        ctxAnswers: answers,
        setCtxAnswers: setAnswers
    };
    
    return (
        <QuestionContext.Provider value={contextValue}>
          {props.children}
        </QuestionContext.Provider>
      );
}

export default QuestionContext;
import { useState, useRef } from 'react';

const Timer = (() => {
  const [time, setTime] = useState(0)
  const countRef = useRef(null)

  const handleStart = (() => {
    countRef.current = setInterval(() => {
      setTime((time) => time + 1)
    }, 1000)
  });

  const handleReset = (() => {
    clearInterval(countRef.current)
    setTime(0)
  });
  
  const handlePause = (() => {
    clearInterval(countRef.current)
  })
  return { time, handleStart, handlePause, handleReset }
});

export default Timer

//https://dev.to/abdulbasit313/how-to-develop-a-stopwatch-in-react-js-with-custom-hook-561b
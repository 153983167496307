import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import useSound from 'use-sound';
import mouseClick from "../assets/mouse_click.mp3";
import clickSound from "../assets/click_sound.mp3";
import '../styles/Home.scss';
import { FaBible } from "react-icons/fa";


function Home(props) {

    const [playMouseClick] = useSound(mouseClick);
    const [playClickSound] = useSound(clickSound);

    return (
        < Fragment >
            <Helmet><title>Bible Quiz</title></Helmet>
            <div id="home">
                <section>
                    <div style={{ textAlign: 'center' }}>
                        <FaBible size={'10rem'} color={"orange"} style={{ marginTop: '2rem', marginBottom: '2rem'} }/>
                    </div>
                    <h1>Bible Quiz</h1>
                    <div className="play-button-container">
                        <ul>
                            <li><Link className="play-button" to="/selectquestions" onMouseEnter={playClickSound} onClick={playMouseClick}>Play</Link></li>
                        </ul>
                    </div>
                </section>
            </div>
        </Fragment >
    )
};

export default Home;
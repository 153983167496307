import React, { useState, useEffect, useRef, useContext } from 'react';
import useSound from 'use-sound';
import { useHistory } from "react-router-dom";
import QuestionContext from '../store/QuestionContext';
import '../styles/Questions.scss';
import correctSound from "../assets/correct_sound.mp3";
import wrongSound from "../assets/wrong_sound.mp3";
import clickSound from "../assets/click_sound.mp3";
import mouseClick from "../assets/mouse_click.mp3";

function Questions(props) {
    const qnsCtx = useContext(QuestionContext);
    const [showQuote, setShowQuote] = useState(false);
    const [selected, setSelected] = useState('');
    const [error, setError] = useState('');
    const radiosWrapper = useRef();
    const currentQuestion = qnsCtx.ctxQuestions[qnsCtx.ctxActiveQuestion];
    const history = useHistory();
    
    const [playCorrectSound] = useSound(correctSound);
    const [playWrongSound] = useSound(wrongSound);
    const [playClickSound] = useSound(clickSound);
    const [playMouseClick] = useSound(mouseClick);

    useEffect(() => {
        const findCheckedInput = radiosWrapper.current.querySelector('input:checked');
        if (findCheckedInput) {
          findCheckedInput.checked = false;
        }
    }, [currentQuestion]);
    
    const changeHandler = (event) => {
        setSelected(event.target.value);
        if (error) {
            setError('');
        }
    }

    const nextClickHandler = (event) => {
        if (selected === '') {
            return setError('Please select one option!');
        }

        if (!showQuote) {
            setShowQuote(true);
        }
        else {
            qnsCtx.setCtxAnswers(prevState => [...prevState, { q: currentQuestion.question, a: selected }]);
            setSelected('');
            
            if (currentQuestion.answer === selected) {
                playCorrectSound();
            } else {
                playWrongSound();
            }
            
            if (qnsCtx.ctxActiveQuestion < qnsCtx.ctxQuestions.length - 1) {            
                qnsCtx.setCtxActiveQuestion(qnsCtx.ctxActiveQuestion + 1);
            } else {
                history.push("/end");
            }
            setShowQuote(false);
        }
    }
    
    return (
        <div id="card">
            <section>
                <div className="question">
                    <h2>{ currentQuestion.question }</h2>
                    <p>Question: {qnsCtx.ctxActiveQuestion + 1} of {qnsCtx.ctxQuestions.length}</p>
                    <div class="control radio-container" ref={radiosWrapper}>
                        {currentQuestion.choices.map((choice, i) => (                            
                            <label className="radio"
                                key={i}
                                style={{
                                    color: selected === choice ? 'yellow' : 'white',
                                    fontSize: selected === choice && '20px'
                                }}
                                onMouseEnter={playClickSound}
                                onClick={playMouseClick}>

                                <input type="radio"
                                    name="answer"
                                    value={choice}
                                    onChange={changeHandler}
                                    disabled={showQuote} />
                                {choice}
                            </label>
                        ))}
                    </div>
                    <div className="timer-container">
                        <p>Time Elapsed: <span>{props.time}</span> sec</p>
                    </div>
                    {showQuote && <div style={{textAlign:"left"}}><p>{currentQuestion.quote}</p></div>}                    
                    {error && <div >{error}</div>}
                </div>
                <div className="button-container">
                    <button className="button is-success is-hovered is-large is-fullwidth" onClick={nextClickHandler}>Next</button>
                </div>
            </section>
        </div>
    )
    
}

export default Questions;

import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import Play from './components/Play';
import Home from './components/Home';
import End from './components/End';
import Timer from "./components/Timer";
import SelectQuestions from './components/SelectQuestions';
import QuestionContext from './store/QuestionContext';
import DatabaseReader from './components/DatabaseReader';

const questions = DatabaseReader().readDB();

function App() {
    
    const qnsCtx = useContext(QuestionContext);

    const { time, handleStart, handlePause, handleReset } = Timer()

    const resetHandler = (() => {
        qnsCtx.setCtxQuestions(questions);
        qnsCtx.setCtxActiveQuestion(0);
        qnsCtx.setCtxAnswers([]);
        handleReset();
    });

    return (
        <div>
            <Switch>
                <Route path="/" exact> <Home/></Route>
                <Route path="/selectquestions" exact>
                    <SelectQuestions questions={ questions }/>
                </Route>
                <Route path="/play" exact>
                    <Play
                        timerStart={handleStart}
                        time={time}
                    />
                </Route>
                <Route path="/end" exact>
                    <End
                        onReset={resetHandler}
                        timerEnd={handlePause}
                        time={time}
                    />
                </Route>
            </Switch>
        </div>
    )
}
export default App;
import React, { useEffect, useState, Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import QuestionContext from '../store/QuestionContext';
import Modal from './Modal';
import useSound from 'use-sound';
import mouseClick from "../assets/mouse_click.mp3";
import clickSound from "../assets/click_sound.mp3";
import '../styles/End.scss';

function End(props) {
    
    const qnsCtx = useContext(QuestionContext);
    const [playMouseClick] = useSound(mouseClick);
    const [playClickSound] = useSound(clickSound);

    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        let correct = 0;
        qnsCtx.ctxAnswers.forEach((result, index) => {
            if (result.a === qnsCtx.ctxQuestions[index].answer) {
                correct++;
            }
        });
        setCorrectAnswers(correct);
        props.timerEnd(); //stop the timer here
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    const checkAnswerHandler = (() => {
        setShowModal(true);
        playMouseClick();
    });

    const calculatePercentage = (() => {
        return ((correctAnswers / qnsCtx.ctxQuestions.length) * 100).toFixed(1)
    });
    
    const endGameHandler = (() => {
        props.onReset();
        playMouseClick();
    });

    return (
        <Fragment>
            {showModal && <Modal
                onClose={() => setShowModal(false)}
                results={qnsCtx.ctxAnswers}
                data={qnsCtx.ctxQuestions}
            />}
            <div id="result-card">
                <section>
                    <div className="results">
                        <h2>Your Results</h2>
                        <p>{correctAnswers} of {qnsCtx.ctxQuestions.length}</p>
                        <p className="percent-result">{calculatePercentage()}%</p>
                        <p className="timer-result">{ props.time} seconds</p>
                    </div>
                    <div className="end-button-container field is-grouped">
                        <Link 
                            className="end-buttons"
                            id="check-answer"
                            onMouseEnter={playClickSound}
                            onClick={checkAnswerHandler}>
                            Check Answers
                        </Link> 
                        <Link
                            to='/'
                            className="end-buttons"
                            id="end-game"
                            onMouseEnter={playClickSound}
                            onClick={endGameHandler}>
                            Main Page
                        </Link>
                    </div>
                </section>
            </div>
        </Fragment>
    )
}

export default End;
import { Fragment, useEffect } from 'react';
import Questions from "./Questions";

function Play(props) {

    useEffect(() => {
        props.timerStart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <Fragment>
            <Questions time={props.time}/>
        </Fragment>
    )
}

export default Play;
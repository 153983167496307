import { database } from '../store/firebase_config';

const DatabaseReader = (() => {

    const readDB = (() => {
        const quizData = [];
        database.collection("biblequizDB")
            .get()    
            .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    quizData.push(doc.data())
                });  
            })
        return quizData;
    });

    return { readDB }
});

export default DatabaseReader
import { Fragment, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import QuestionContext from '../store/QuestionContext';
import useSound from 'use-sound';
import mouseClick from "../assets/mouse_click.mp3";
import clickSound from "../assets/click_sound.mp3";
import '../styles/SelectQuestions.scss';

function SelectQuestions(props) {

    const qnsCtx = useContext(QuestionContext);
    const [playMouseClick] = useSound(mouseClick);
    const [playClickSound] = useSound(clickSound);
    
    useEffect(() => {
        qnsCtx.setCtxQuestions(props.questions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const shuffle = (array) => {
        var currentIndex = array.length;
        var randomIndex;
      
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
    
        return array;
    }

    const selectQuestions = (type, numQns) => {
        let questions = [];

        qnsCtx.ctxQuestions.forEach(qns => {
            if (qns.category === type) {
                questions.push(qns);
            }            
        });

        questions = shuffle(questions);
        
        qnsCtx.setCtxQuestions(questions.slice(0, numQns));
    }

    const selectBookofActs = (() => {
        selectQuestions("acts", 15)
        playMouseClick();
    })

    return (
        <Fragment>
            <div id="select-questions">
                <section>
                    <h1>Select Question Type</h1>
                    <Link className="select-button" to="/play" onMouseEnter={playClickSound} onClick={selectBookofActs}>Book of Acts</Link>
                </section>
            </div>
        </Fragment>
    )
}

export default SelectQuestions;
import firebase from 'firebase/app';
import 'firebase/firestore'; 

const firebaseConfig = {
    apiKey: "AIzaSyBdAxxxoMDt2fbGsWBWKLwftdUAxypwN9E",
    authDomain: "junkal-webapp.firebaseapp.com",
    projectId: "junkal-webapp",
    storageBucket: "junkal-webapp.appspot.com",
    messagingSenderId: "963867101247",
    appId: "1:963867101247:web:830534bc82fdbdc139908a"
};

firebase.initializeApp(firebaseConfig);

const database = firebase.firestore();

export { database };